import * as React from 'react';
import Layout from '../components/layout';
import { ContentBlock } from '../components/Blocks';

const NotFoundPage = () => (
  <Layout>
    <ContentBlock>Seite nicht gefunden</ContentBlock>
  </Layout>
);

export default NotFoundPage;
